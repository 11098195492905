<template>
  <div @click.self="$emit('input', false)" v-if="value" class="v-slider-backdrop">
    <div class="container">
      <div class="v-slider">
        <img @click="$emit('input', false)" class="close" src="@/assets/svg/close/white.svg" alt="">
        <div class="v-slider__main-photo">
          <img @click="prev" v-if="data.images.length > 1" class="prev" src="@/assets/svg/icon-slider.svg" alt="">
          <img @click="next" v-if="data.images.length > 1" class="next" src="@/assets/svg/icon-slider.svg" alt="">
          <img @click="$emit('input', false)" class="close" src="@/assets/svg/close/white.svg" alt="">
          <img @click="visibleDownload = true" class="download" src="@/assets/svg/download.svg" alt="">
          <div class="wrapper">
            <div class="wrapper__items">
<!--              <img v-for="(photo, key) in data.images" :key="photo[key]" :src="getUserAvatar(photo, true)" alt="">-->
              <img :src="getUserAvatar(data.images[index], true)" alt="">
            </div>
          </div>
        </div>

        <div class="v-slider__carousel">
<!--          <div class="v-slider__carousel-wrapper" :style="translateY">-->
<!--            <img @click="index = key" :class="{ active: index === key }" v-for="(photo, key) in data.images" :key="photo[key]" :src="getUserAvatar(photo, true)" alt="">-->
<!--          </div>-->
          <div class="v-slider__carousel-wrapper">
            <img :ref="key" @click="goTo(key), index = key" :class="{ active: index === key }" v-for="(photo, key) in data.images" :key="photo[key]" :src="getUserAvatar(photo, true)" alt="">
          </div>
        </div>
      </div>
    </div>
    <photo-download :images="data.imagesSrc" v-model="visibleDownload" @close="visibleDownload = false" />
  </div>
</template>

<script>
import { getUserAvatar } from "@/utils/user";
export default {
  name: "VSlider",
  components: {
    PhotoDownload: () => import('../template/work-requests/ViewRequests/PhotoDownload.vue'),
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    },
    count: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      index: 0,
      visibleDownload: false,
      getUserAvatar
    }
  },
  watch: {
    value(val) {
      if(val) {
        this.index = this.count
      }
    }
  },
  computed: {
    translateY() {
      if(window.innerWidth > 1200) {
        return {transform: `translateY(-${this.index * 150}px)`}
      } else {
        return ''
      }
    }
  },

  methods: {
    prev() {
      if(this.index !== 0) {
        this.index--
      }
    },
    next() {
      if(this.index !== this.data.images.length - 1) {
        this.index++
      }
    },
    goTo (key) {
      document.querySelector('.v-slider__carousel').scrollTo({
        top: this.$refs[+key][0].offsetTop - 30,
        left: 100,
        behavior: "smooth",
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-slider-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    overflow: hidden;
    background: rgba(153, 153, 153, 0.8);
  }
  .v-slider {
    position: relative;
    display: flex;
    gap: 30px;
    height: calc(100vh - 100px);
    margin: 50px 70px 50px 50px;
    background: rgba(52, 52, 50, 0.9);
    border-radius: 20px;

    @media (max-width: 1200px) {
      flex-direction: column;
      gap: 56px;
      margin: 0;
      height: 100vh;
    }

    .close {
      position: absolute;
      cursor: pointer;
      right: -40px;
      top: -20px;
      width: 35px;
      height: 35px;
    }

    &__main-photo {
      position: relative;
      max-width: 1050px;
      width: 100%;
      height: 100%;
      margin: 30px 30px 0 30px;

      @media (max-width: 1200px) {
        margin: 0;
      }


      .prev {
        position: absolute;
        left: 0;
        top: calc(50% - 52px);
        transform: rotate(-90deg);
        z-index: 10000;
        cursor: pointer;
      }
      .next {
        position: absolute;
        right: 0;
        top: calc(50% - 52px);
        z-index: 10000;
        transform: rotate(90deg);
        cursor: pointer;

        @media (max-width: 768px) {
          right: 0;
        }
      }

      .close {
        display: none;
        position: absolute;
        top: 5px;
        right: 0;
        transform: rotate(-90deg);
        z-index: 10000;
        cursor: pointer;

        @media (max-width: 1200px) {
          display: inline-block;
        }
      }

      .download {
        width: 44px;
        height: 44px;
        top: 10px;
        left: 10px;
        position: absolute;
        z-index: 10;
        cursor: pointer;

        @media (max-width: 1200px) {
          left: 0;
          bottom: 0;
          top: initial;
        }
        @media (max-width: 768px) {

        }
      }
      .wrapper {
        overflow: hidden;
        height: 100%;
        max-height: 700px;
        max-width: 100%;

        @media (max-width: 1200px) {
          max-height: 450px;
        }
        @media (max-width: 768px) {
          max-width: 100%;
          max-height: 270px;
        }

        &__items {
          display: flex;
          width: 100%;
          height: calc(100% - 100px);
          transition: .5s;

          img {
            min-width: 100%;
            object-fit: cover;
            @media (max-width: 1200px) {
              height: 450px;
            }
            @media (max-width: 768px) {
              height: 270px;
            }
          }
        }
      }
    }

    &__carousel {
      height: 100%;
      max-width: 170px;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-bottom: 50px;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: 1200px) {
        overflow: auto;
        max-width: 100%;
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        transition: .3s;

        @media (max-width: 1200px) {
          flex-direction: row;
          gap: 2px;
        }

        img {
          width: 150px;
          height: 150px;
          border-radius: 5px;
          cursor: pointer;
          object-fit: cover;

          @media (max-width: 1200px) {
            width: 132px;
            height: 133px;
          }

          &.active {
            border: 2px solid #E21F1F;
          }
        }
      }
    }
  }
</style>